<template>
  <div>
    <div class="events">
      <EventItemsList
        v-if="pinnedEvents"
        :items="pinnedEventItems"
        :pinned="true"
        :allow-click="true"
        :timeAtCategory="true"
        :loading="loading"
        @select="openEventPage"
      />
      <EventItemsList
        :items="regularEventItems"
        :allow-click="true"
        :timeAtCategory="true"
        :loading="loading"
        @select="openEventPage"
      />
      <div class="d-flex justify-center" v-if="regularEventItems.length === 50">
        <v-btn color="primary" class="show-more-button" :to="{ name: 'events' }">
          {{ $t("main.SHOW_MORE_EVENTS") }}
        </v-btn>
      </div>
      <EventDialog :show="showEventDialog" :event="dialogEvent" @close="closeDialog"/>

      <div v-if="pinned && events.length == 0">
        <p>{{ $t("main.HOWTO_PIN_NOTICE") }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import EventService from '@/services/EventService';
import EventDialog from './EventDialog';
import EventItemsList from "./EventItemsList.vue";
import {mapGetters} from "vuex";

export default {
  name: "EventList",
  components: {
    EventItemsList,
    EventDialog
  },
  props: {
    events: {
      type: Array,
      required: true
    },
    eventsLive: {
      type: Array,
      default: () => []
    },
    pinned: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    liveEvents: {
      type: Boolean,
      default: false
    },
    pinnedEvents: {
      type: Array,
      required: false
    },
    filteredCountries: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      showEventDialog: false,
      dialogEvent: null,
    }
  },
  computed: {
    ...mapGetters({
      countryIds: 'organisationCountrySelectionsStore/countryIds',
    }),
    regularEventItems() {
      return this.processEvents(this.liveEvents ? this.eventsLive : this.events, this.liveEvents ? false : this.filteredCountries);
    },
    pinnedEventItems() {
      return this.processEvents(this.pinnedEvents || [], this.filteredCountries);
    }
  },
  methods: {
    processEvents(events, isFilter) {
      return events.filter(item => !isFilter || this.countryIds.includes(item.event.country.id))
          .map(({ id, event, isNew, travelersNow, travelersPotentiallyAffected }) => {
        const icon = EventService.getIconFileNameByCategorty(event.category.code, event.level);

        return {
          id,
          text: event.title,
          createdAt: event.significantUpdateAt,
          category: event.category.name,
          categoryIcon: icon,
          isNew,
          staticPageUrl: event.staticPageUrl,
          gmUniqueEventId: event.gmUniqueEventId,
          travelersNow,
          travelersPotentiallyAffected
        };
      });
    },
    openEventPage(event) {
      this.dialogEvent = event;
      this.showEventDialog = true;
    },
    closeDialog() {
      this.showEventDialog = false;
    }
  }
};
</script>

<style scoped>
  .events {
    /*padding-left: 0.75rem;
    padding-right: 0.75rem;*/
    overflow-y: auto;
    padding-bottom: 5rem;
    height: 100%;
  }

  .events::-webkit-scrollbar {
    display: none;
  }

  .show-more-button {
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0.0089em;
  }
</style>