<template>
  <div class="main-view">

    <div class="map-contols">
      <v-card
        class="mt-6 map-card"
      >
        <v-container class="pa-8">
          <DestinationWorldMapControl
            :iso2="nationalityIso2"
            @nationality-selected="nationalitySelected"
          />

          <DestinationWorldMapUseCase
            @selected-filter="filterSelected"
          />
        </v-container>
      </v-card>

      <v-card class="legend map-card" v-if="nationalityIso2">
        <v-container
          class="pa-6"
        >
          <DestinationWorldMapLegend />
        </v-container>
      </v-card>
    </div>

    <v-btn
      v-if="countryIso2"
      class="back-to-earth"
      @click="countryIso2=null"
      color="primary"
    >
      {{ $t('main.BACK_TO_WORLDMAP') }}
    </v-btn>

    <DestinationWorldMap
      class="main-map"
      :countries-geojson="geoJson"
      :iso2="countryIso2"
      :nationalityIso2="nationalityIso2"
      :updateMapFlag="updateMapFlag"
      :selectedFilter="selectedFilter"
      @country-selected="countrySelected"
    />

  </div>
</template>

<script>
import countriesGeoJson from '../assets/world_30sep2021.json'
import moment from "moment"
import DestinationWorldMap from "@/components/map/DestinationWorldMap"
import DestinationWorldMapControl from "@/components/map/DestinationWorldMapControl"
import DestinationWorldMapLegend from "@/components/map/DestinationWorldMapLegend"
import DestinationWorldMapUseCase from "../components/map/DestinationWorldMapUseCase.vue";


export default {
  name: 'DestinationMapView',
  components: {
    DestinationWorldMapUseCase,
    DestinationWorldMap,
    DestinationWorldMapControl,
    DestinationWorldMapLegend,
  },
  data() {
    return {
      countryIso2: null,
      nationalityIso2: null,
      search: null,
      dateStart: moment().format("YYYY-MM-DD"),
      dateEnd: moment().add(2, 'weeks').format("YYYY-MM-DD"),
      updateMapFlag: false,
      selectedFilter: 'ErVisaRequiredForStay'
    }
  },
  computed: {
    geoJson() {
      return countriesGeoJson
    },
  },
  methods: {
    countrySelected(countryIso2) {
      this.countryIso2 = countryIso2
    },
    nationalitySelected(countryIso2) {
      this.nationalityIso2 = countryIso2
      this.updateMapFlag = !this.updateMapFlag
    },
    filterSelected(value) {
      this.selectedFilter = value
    }
  }

}
</script>

<style scoped>
  .main-view {
      margin: auto;
  }

  .main-map {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .map-contols {
    width: 440px;
    position: absolute;
    top: 1rem;
    left: 1rem;
    z-index: 1001;
  }

  .legend {
    margin-top: 1rem;
    z-index: 1001;
    font-size: 70%!important;
    line-height: 0.8rem;
  }

  .back-to-earth {
    position: absolute;
    z-index: 1001;
    top: 1rem;
    left: 45%;
  }

  .map-card {
    border-radius: 10px;
  }
</style>
