<template>
  <v-dialog v-model="showDialog" max-width="700" @click:outside="close">
    <v-card v-if="news">
      <v-card-title class="news-dialog-title">
        <span>{{ news.title }}</span>
        <v-btn @click="close" text><v-icon>close</v-icon></v-btn>
      </v-card-title>

      <v-card-text style="margin-top: 20px">
        <div class="date-header">
          <img src="@/assets/clock.png" class="clock" alt=""/>
          <p>{{ news.createdAt|i18n_dateToGerman }} {{ news.createdAt|i18n_datetimeToGermanTime }}</p>
        </div>
        <v-divider style="margin-top: -10px; margin-bottom: 15px"/>
        <div v-html="news.text"></div>
      </v-card-text>

      <v-card-actions style="padding-bottom: 16px">
        <v-spacer></v-spacer>
        <v-btn color="primary" style="letter-spacing: normal; width: 150px" @click="close">{{ $t("main.CLOSE_ACTION") }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'NewsDialog',
  props: {
    show: {
      type: Boolean,
      required: true
    },
    news: {
      type: Object
    }
  },
  data() {
    return {
      showDialog: false
    }
  },
  methods: {
    close() {
      this.$emit('close')
    }
  },
  watch: {
    show() {
      this.showDialog = this.show
    }
  }

}
</script>

<style scoped>
  .v-dialog__content {
    left: 150px;
  }
</style>

<style>

  .news-dialog-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #003B71 !important;
    font-size: 20px !important;
    font-weight: 400 !important;
    background-color: #1976d21a;
  }

  .news-dialog-title span {
      flex: 1;
  }

  .date-header {
    display: flex;
    align-items: center;
  }

  .clock {
      width: 15px;
      height: 15px;
      margin-right: 10px;
      margin-bottom: 16px;
  }


</style>