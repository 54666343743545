<template>
  <v-row>
    <v-col>
      <b class="legend-title">{{ $t('main.LEGEND').toUpperCase() }}</b>
    </v-col>
    <v-col class="legend-row">
      <div class="legend legend-YES" /> <span class="ml-2 legend-item">{{ $t('main.YES') }}</span>
    </v-col>
    <v-col class="legend-row">
      <div class="legend legend-NO" /> <span class="ml-2 legend-item">{{ $t('main.NO') }}</span>
    </v-col>
    <v-col class="legend-row">
      <div class="legend legend-UNKNOWN" /> <span class="ml-2 legend-item">{{ $t('main.UNKNOWN') }}</span>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'DestinationMapLegend',
}
</script>

<style scoped>
  .legend {
    height: 12px;
    width: 12px;
  }

  .legend-YES {
    background-color: #FF2114;
  }

  .legend-NO {
    background-color: #80D481;
  }

  .legend-UNKNOWN {
    background-color: #80808090;
  }

  .legend-row {
    display: flex;
    align-items: center;
  }

  .legend-title {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.5px;
    text-align: left;
    color: #00000099;
  }

  .legend-item {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.5px;
    text-align: left;
  }

</style>