<template>
  <div>
    <v-autocomplete
      v-model="iso2Value"
      :items="sortedCountries"
      item-text="nameShort"
      item-value="iso2"
      :clearable="true"
      :loading="loadingCountries"
      @change="clickCountry"
      :label="$t('main.MAP_NATIONALITY')"
      :placeholder="$t('main.SEARCH_FOR_COUNTRY')"
    />

    <div class="use-case-note">{{ $t('main.MAP_FORM_NOTE') }}</div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex"


export default {
  name: 'DestinationWorldMapControl',
  props: [
    'iso2'
  ],
  data() {
    return {
      iso2Value: null,
      loadingCountries: true,
      restrictionType: "TOURISM",
      defaultCountry: 'DE',
      sortedCountries: []
    }
  },
  mounted() {
    this.doCountriesFetchAll()
  },
  computed: {
    ...mapState({
      countries: state => state.mapCountriesStore.entries,
      localStorageDefaultNationality: state => state.uiStore.defaultNationality,
    }),
  },
  methods: {
    ...mapActions({
      countriesFetchAll: "mapCountriesStore/fetchAll",
    }),
    doCountriesFetchAll() {
      this.loadingCountries = true
      this.countriesFetchAll().then(() => {
        this.loadingCountries = false
        this.sortedCountries = this.countries.sort((a, b) => a.nameShort.localeCompare(b.nameShort))
        this.iso2Value = this.countries.find(item =>
            item.iso2 === (this.localStorageDefaultNationality || this.defaultCountry)
        ) || this.countries.find(item =>
            item.iso2 === this.defaultCountry
        );
        if (this.iso2Value) {
          this.$emit('nationality-selected', this.iso2Value.iso2)
        }
      })
    },
    clickCountry() {
      this.$emit('nationality-selected', this.iso2Value)
    },
  },
  watch: {
    iso2: {
      immediate: true,
      handler(newVal) {
        this.iso2Value = newVal
      },
    }
  }
}
</script>

<style scoped>

  .use-case-note {
    font-size: 12px;
    color: gray;
    font-weight: 400;
    line-height: 16px;
    padding-bottom: 5px;
  }

</style>