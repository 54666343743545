<template>
  <v-container class="pa-0">
    <v-list shaped>
      <v-subheader>{{ $t('main.MAP_USE_CASE').toUpperCase() }}</v-subheader>
      <v-list-item-group color="primary" v-model="selectedFilter" mandatory>
        <v-list-item
          v-for="entryRestriction in useCaseList"
          :key="entryRestriction.value"
          :value="entryRestriction.value"
        >
          <v-list-item-icon>
              <img :src="entryRestriction.icon" class="logo" alt="visa icon" />
          </v-list-item-icon>
          <v-list-item-title>{{ entryRestriction.text }}</v-list-item-title>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-container>
</template>

<script>

export default {
  name: 'DestinationWorldMapUseCase',
  data() {
    return {
      useCaseList: [
        {'value': 'ErVisaRequiredForStay', 'text': this.$i18n.t('main.VISA_REQUIRED'), 'icon': require("@/assets/summary_icons/visa-icon.svg")},
        {'value': 'ErEntryPermitRequiredForStay', 'text': this.$i18n.t('main.ENTRY_PERMIT_REQUIRED'), 'icon': require("@/assets/summary_icons/entry_permit-icon.svg")},
        {'value': 'ErIDSufficientForEntry', 'text': this.$i18n.t('main.ID_REQUIRED'), 'icon': require("@/assets/summary_icons/id-icon.svg")},
        {'value': 'ErRequiredImmunizations', 'text': this.$i18n.t('main.IMMUNIZATION_REQUIRED'), 'icon': require("@/assets/summary_icons/immunization-icon.svg")},
        {'value': 'ErCompulsoryHealthInsurance', 'text': this.$i18n.t('main.HEALTH_INSURANCE_REQUIRED'), 'icon': require("@/assets/summary_icons/health_insurance-icon.svg")},
      ],
      selectedFilter: null
    }
  },
  watch: {
    selectedFilter: {
      handler() {
        this.$emit('selected-filter', this.selectedFilter)
      },
    }
  }
}
</script>

<style scoped>

  .logo {
    width: 20px;
    height: 20px;
  }

</style>