<template>
  <l-map
    ref="map"
    :zoom="zoomValue"
    :center="centerPositionValue"
    :options="mapOptions"
    class="mainMap"
    style="z-index: 0;"
  >
    <v-progress-linear indeterminate class="map-loading" v-if="loadingCountriesDetails"></v-progress-linear>
    <l-control-zoom position="bottomright" ></l-control-zoom>
    <l-tile-layer
      :url="mapUrl"
      :attribution="attribution"
    />
    <l-geo-json
      ref="geoJsonWorld"
      :geojson="geoJsonWorld"
      :options="geoJsonOptions"
      :optionsStyle="geoJsonStyle"
    />
  </l-map>
</template>

<script>
import moment from "moment"
import { latLng } from "leaflet";
import { LMap, LTileLayer, LGeoJson, LControlZoom  } from 'vue2-leaflet';
import 'leaflet.pattern'
import { mapState, mapActions } from "vuex";

export default {
  name: 'DestinationWorldMap',
  components: {
    LMap,
    LTileLayer,
    LGeoJson,
    LControlZoom,
  },
  props: {
    iso2: {
      type: String,
    },
    countriesGeojson: {
      type: Object,
    },
    dateStart: {
      type: String,
      default: moment().format("YYYY-MM-DD")
    },
    dateEnd: {
      type: String,
      default: moment().add(1, 'days').format("YYYY-MM-DD"),
    },
    restrictionType: {
      type: String,
      default: "TOURISM",
    },
    centerPosition: {
      type: Object,
      default: () => latLng(30.41322, 0),
    },
    zoom: {
      type: Number,
      default: 3
    },
    nationalityIso2: {
      type: String
    },
    updateMapFlag: {
      type: Boolean
    },
    selectedFilter: {
      type: String
    }
  },
  data() {
    return {
      cidmerCountries: null,

      zoomValue: 3,
      centerPositionValue: latLng(30.41322, 0),
      attribution:
        '&copy; <a href="http://osm.org/copyright" target="_blank">OpenStreetMap</a> contributors',

      /* per default we hide the geojson layer, and show them only when the datecheck
         returns data */
      geoJsonStyle: {
        fillOpacity: 0,
        weight: 0
      },
      mapOptions: {
        zoomControl: false
      },

      loadingCountriesDetails: false
    }
  },
  computed: {
    ...mapState({
      language: state => state.uiStore.language,
    }),

    mapUrl() {
      // might be a good idea to make this i18n friendly
      return 'https://b.tile.openstreetmap.de/{z}/{x}/{y}.png'
    },

    geoJsonOptions() {
      return {
      }
    },
    geoJsonWorld() {
      return this.countriesGeojson
    },
  },
  methods: {
    getColorByStatus(status) {
      let color = "gray" // unknown
      if (status == 'NO') {
        color = "green"
      }
      if (status == 'YES') {
        color = "red"
      }
      return color
    },
    updateMap() {
      /* VueLeaflet ist not as reactive as it should be, it is not possible to update
         the different features (countries) in a computed function, instead we must watch
         for updates on the base data and push the new style to the layers of the feature... */
      this.$nextTick(() => {
        if (this.$refs.geoJsonWorld && this.$refs.geoJsonWorld.mapObject) {
          this.updateWorldCountryMap()
        }
      });
    },
    onCountryChanged(iso2) {
      if (iso2) {
        this.zoomOnCountry(iso2)
        this.$emit('country-selected', iso2)
      } else {
        this.resetMap()
      }
    },
    resetMap() {
      this.$nextTick(() => {
        this.$refs.map.mapObject.setView([30.41322, 1], this.zoomValue)

        this.updateWorldCountryMap()
      })
    },
    zoomOnCountry(iso2) {
      this.$nextTick(() => {
        this.$refs.geoJsonWorld.mapObject.eachLayer((layer) => {
          let featureIso2 = layer.feature.properties.iso_a2

          if (iso2 == featureIso2) {
            this.$refs.map.mapObject.fitBounds(
              layer.getBounds(), {
              padding: [100, 10]
            })
          }
        })
      })
    },

    getCountryStyle(country, iso2) {
      return this.getCountryStyleEntryRestrictions(country, iso2)
    },

    getCountryStyleEntryRestrictions(country, iso2) {
      let fillOpacity = 0.4

      let style = {
        'fillColor': this.getColorByStatus(country.status),
        'weight': 0.5,
        //'color': 'black',
        'fillOpacity': fillOpacity,
        'fillPattern': null
      }

      return style
    },

    updateWorldCountryMap() {
      this.$refs.geoJsonWorld.mapObject.eachLayer((layer) => {
        let iso2 = layer.feature.properties.iso_a2

        layer.unbindTooltip()
        layer.off("click")

        if (!this.cidmerCountries) {
          return;
        }

        let countryCidmer = this.cidmerCountries.find(item => item.destination.iso2 === iso2)
        if (countryCidmer && countryCidmer.items) {
          if (this.selectedFilter !== 'ErIDSufficientForEntry') {
            countryCidmer.status = countryCidmer.items.find(item => item.type === this.selectedFilter)['status']
          } else {
            countryCidmer.status = countryCidmer.items.find(item => item.type === this.selectedFilter)['status'] === 'YES' ? 'NO' : 'YES';
          }
        }
        if (countryCidmer) {
          const style = this.getCountryStyle(countryCidmer, iso2)
          layer.setStyle(style)

          this.getTooltipText(countryCidmer, layer)
        }
      });
    },
    getTooltipText(countryCidmer, layer) {
      if (countryCidmer && countryCidmer.items) {
        let noteKey = 'note' + this.language.charAt(0).toUpperCase() + this.language.slice(1);

        let languages = countryCidmer.items.find(item => item.type === 'CiLanguages')[noteKey];
        let currency = countryCidmer.items.find(item => item.type === 'CiCurrency')[noteKey];
        let capital = countryCidmer.items.find(item => item.type === 'CiCapitalcity')[noteKey];

        layer.bindTooltip(
            "<h2>" + countryCidmer.destination.nameShort + "</h2>" + "<b>"
            + this.$t('main.LANGUAGES') + ':' + "</b>" + languages + "<br>"
            + "<b>" + this.$t('main.CURRENCY') + ':' + "</b>" + currency + "<br>"
            + "<b>" + this.$t('main.CAPITAL_CITY') + ':' + "</b>" + capital,
            { permanent: false, sticky: true }
        )
      }
    }
  },
  watch: {
    iso2: {
      immediate: true,
      handler(newVal) {
        this.onCountryChanged(newVal)
      },
    },
    updateMapFlag: {
      immediate: true,
      handler() {
        if (this.nationalityIso2) {
          this.loadingCountriesDetails = true
          this.$mapRepository.getMapCidmerDestinationContent(this.nationalityIso2).then((result) => {
            this.cidmerCountries = result.results
            this.updateMap()
            this.loadingCountriesDetails = false
          })
        }
      },
    },
    selectedFilter: {
      immediate: true,
      handler() {
        this.updateMap()
      },
    },
    centerPosition: {
      immediate: true,
      handler(newVal) {
        this.centerPositionValue = newVal
      },
    },
    zoom: {
      immediate: true,
      handler(newVal) {
        this.zoomValue = newVal
      },
    },
  }
}
</script>

<style scoped>

  /deep/ path.leaflet-interactive:focus {
    outline: none;
  }

  .map-loading {
    z-index: 1000;
    margin-top: 1px;
  }
</style>
