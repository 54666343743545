<template>
  <div class="index-map-view">
    <div class="header">
      <v-img
        cover
        height="250px"
        :src="require('@/assets/prop-background.jpg')"
        class="search-bar-img"
      >
        <div class="search-container">
          <DestinationSearchBar @search="search" @show-error-message="showErrorMessage"/>
        </div>
      </v-img>
    </div>
    <div class="background-container">
      <div class="index-panels">
        <div class="events-panels" v-if="$hasPermission('a3m_core.DestinationManagerEvents')">
          <div class="panel panel-1a">
            <v-container height="100%" style="padding: 18px 18px 18px">
              <div class="event-list">
                <div class="event-header">
                  <div class="event-header-title">
                    <div class="all-events" style="color: #003b71">
                      <Svg-icon type="mdi" :path="eventIcon" style="margin-right: 10px"></Svg-icon>
                      {{ $t("main.ALL_GMEVENTS") }}
                    </div>
                    <div class="live-events" v-if="$hasPermission('a3m_core.DestinationManagerBosysBookings')">
                      <v-switch color="primary" @change="toggleLive" inset></v-switch>
                      <v-badge color="red" offset-x="2" offset-y="15" :content="eventsWithIsUnreadLive.length" v-if="eventsWithIsUnreadLive.length">
                        <span :style="computedStyle">{{ $t("main.LIVE_EVENTS") }}</span>
                      </v-badge>
                      <span :style="computedStyle" v-else>{{ $t("main.LIVE_EVENTS") }}</span>
                    </div>
                  </div>
                  <div v-if="filteredEvents" class="ma-3">
                    {{ $t("main.FILTERED_BY_FAVORITES") }}.
                    <a @click="filteredEvents=false">{{ $t("main.SHOW_ALL_EVENTS") }}</a>
                  </div>
                  <div v-else-if="!filteredEvents && countryIds.length !== 0" class="ma-3">
                    <a @click="filteredEvents=true">{{ $t("main.ENABLE_FILTERING") }}</a>
                  </div>
                </div>
                <EventList
                  :events="eventsWithIsUnread"
                  :eventsLive="eventsWithIsUnreadLive"
                  :loading="isLoading"
                  :liveEvents="liveEvents"
                  :pinnedEvents="eventsPinnedWithIsUnread"
                  :filteredCountries="filteredEvents"
                />
              </div>
            </v-container>
          </div>
        </div>
        <div class="news-panels" :class="{ of3: !has4Panels, of4: has4Panels}">
          <!-- Last DM news -->
          <div class="horizontal-panel" v-if="lastDMNews">
            <NewsLastArticle
              :news="destinationManagerNews"
              class="news-article"
            />
            <v-btn icon @click="closeLastNews" color="primary" class="close-news-article">
              <v-icon>close</v-icon>
            </v-btn>
          </div>
          <div class="news-block">
            <!--Ticker-->
            <div class="panel panel-2">
              <div class="news-title">
                  <div class="news-header-title">
                    <div class="news-header">
                      {{ $t('main.TICKER') }}
                    </div>
                    <v-btn text color="#003b71" small class="mt-1"
                           :to="{name: 'organisation-countries-selection'}">

                      <v-badge v-if="countryIds.length" offset-x="10" offset-y="15" color="green" :content="countryIds.length">
                        <span class="favorites-button-text mr-2">{{ $t("main.FAVORITES") }}</span><v-icon class="mr-2 mb-1">edit</v-icon>
                      </v-badge>
                      <div v-else>
                        <span class="favorites-button-text mr-2">{{ $t("main.FAVORITES") }}</span><v-icon class="mr-2 mb-1">edit</v-icon>
                      </div>
                    </v-btn>
                  </div>
              </div>
              <v-container class="news-container-ticker">
                <div v-if="filteredNews" class="ma-3">
                    {{ $t("main.FILTERED_BY_FAVORITES") }}.
                    <a @click="filteredNews=false">{{ $t("main.SHOW_ALL_ENTRIES") }}</a>
                </div>
                <div v-else-if="!filteredNews && countryIds.length !== 0" class="ma-3">
                    <a @click="filteredNews=true">{{ $t("main.ENABLE_FILTERING") }}</a>
                  </div>
                <div class="news-list">
                  <NewsList
                    :news="ticker"
                    :loading="isLoading"
                    :filteredCountries="filteredNews"
                  />
                </div>
              </v-container>
            </div>
            <div class="important-news">
              <!--Important news-->
              <div class="panel panel-3">
                <div class="news-title">
                  <div class="news-header-title">
                    <div class="news-header">
                      {{  $t("main.IMPORTANT_NEWS")  }}
                    </div>
                  </div>
                </div>
                <v-container class="news-container">
                  <div class="news-list">
                    <div>
                      <NewsList
                        :news="importantNews"
                        :loading="isLoading"
                      />
                    </div>
                  </div>
                </v-container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <v-snackbar v-model="errorMessage" :timeout="2000" color="error">{{ $t('main.SEARCH_DATE_ERROR') }}</v-snackbar>

    <v-btn
      fab
      fixed
      bottom
      right
      style="z-index: 11"
      color="primary"
      @click="reloadNews"
      :loading="isLoading"
    >
      <v-icon>refresh</v-icon>
    </v-btn>

  </div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from "vuex";
//import EntryRestrictionChangeLog from '@/components/entryrestriction/EntryRestrictionChangeLog';
import EventList from '@/components/event/EventList';
import NewsList from '@/components/news/NewsList';
import NewsLastArticle from '@/components/news/NewsLastArticle'
import DestinationSearchBar from '@/components/destination/DestinationSearchBar'
import SvgIcon from "@jamescoyle/vue-icon";
import {mdiSquareRoundedBadge} from "@mdi/js";

export default {
  name: 'IndexView',
  components: {
    SvgIcon,
    //EntryRestrictionChangeLog,
    EventList,
    NewsList,
    NewsLastArticle,
    DestinationSearchBar
  },
  data() {
    return {
      isLoading: false,
      amountLiveEvents: 0,
      liveEvents: false,
      lastUnreadArticleId: null,
      eventIcon: mdiSquareRoundedBadge,
      filteredEvents: false,
      filteredNews: false,
      errorMessage: false
    }
  },
  mounted() {
    this.reloadNews()
    this.SET_AUTOCOMPLETE_FORM(false)
  },
  computed: {
    ...mapState({
      user: state => state.authStore.user,
      language: state => state.uiStore.language,
      lastArticleId: state => state.uiStore.lastArticleId
    }),
    ...mapGetters({
      importantNews: 'newsStore/importantNews',
      ticker: 'tickerStore/withIsUnread',
      destinationManagerNews: 'newsStore/destinationManagerNews',
      eventsWithIsUnread: 'eventsLatestSignificantUpdatesStore/withIsUnread',
      eventsWithIsUnreadLive: 'eventsLatestSignificantUpdatesStore/withIsUnreadLive',
      eventsPinnedWithIsUnread: 'eventsLatestSignificantUpdatesPinnedStore/withIsUnread',
      countryIds: 'organisationCountrySelectionsStore/countryIds',
    }),
    lastDMNews() {
      return this.$store.state.uiStore.showLastDMNews;
    },
    cdbAccessKey() {
      return this.user ? this.user.cdbAccessKey : ''
    },
    has4Panels() {
      return this.$hasPermission('a3m_core.DestinationManagerEvents')
    },
    computedStyle() {
      const baseStyles = {
        fontSize: '16px',
        fontWeight: 500,
        lineHeight: '32px',
        letterSpacing: '0.25px',
      };

      if (this.eventsWithIsUnreadLive.length) {
        return {
          ...baseStyles,
          color: '#f44336'
        };
      } else {
        return {
          ...baseStyles,
          color: '#003b71'
        };
      }
    }
  },
  methods: {
    ...mapActions({
      setLastNewsVisitDateToNow: 'uiStore/setLastNewsVisitDateToNow',
      fetchNewsAndLatestUpdates: 'uiStore/fetchNewsAndLatestUpdates',
      eventsFetchAll: 'eventsLatestSignificantUpdatesStore/fetchAll',
      checkShowLastDMNews: 'newsStore/checkShowLastDMNews'
    }),
    ...mapMutations({
      SET_SHOW_LAST_DM_NEWS: 'uiStore/SET_SHOW_LAST_DM_NEWS',
      SET_LAST_SHOW_ARTICLE_ID: 'uiStore/SET_LAST_SHOW_ARTICLE_ID',
      SET_SEARCH_PARAMS: 'uiStore/SET_SEARCH_PARAMS',
      SET_AUTOCOMPLETE_FORM: 'uiStore/SET_AUTOCOMPLETE_FORM',
    }),
    doFetchNewsAndLatestUpdates() {
      this.isLoading = true

      let eventPromise = this.eventsFetchAll({ variables: { limit: 50, countryFilter: false  }, force: true })
      let newsPromise = this.fetchNewsAndLatestUpdates()

      Promise.all([eventPromise, newsPromise]).then(() => {
        // we wait two seconds before we mark the page as visited, to that all
        // tasks are definitly run
        setTimeout(() => {
          this.setLastNewsVisitDateToNow()
        }, 2000)
        this.isLoading = false
        this.checkShowLastDMNews().then((lastNewsId) => {
          this.lastUnreadArticleId = lastNewsId;
        }).catch(() => {
          this.lastUnreadArticleId = null;
        });
      }).catch(() => {
        this.isLoading = false
      })
    },
    reloadNews() {
      this.doFetchNewsAndLatestUpdates()
    },
    closeLastNews(){
      this.SET_SHOW_LAST_DM_NEWS(false)
      this.SET_LAST_SHOW_ARTICLE_ID(this.lastUnreadArticleId)
    },
    search(searchData) {
      this.SET_SEARCH_PARAMS(searchData)
      this.$router.push({
        name: "destination-result",
        params: searchData
      });
    },
    toggleLive() {
      this.liveEvents = !this.liveEvents;
    },
    showErrorMessage(errorMessage) {
      this.errorMessage = errorMessage
    }
  },
  watch: {
    countryIds: {
      immediate: true,
      handler(newValue)
      {
        this.filteredEvents = newValue.length !== 0;
        this.filteredNews =newValue.length !== 0;
      }
    }
  }
}
</script>

<style scoped>

  .index-map-view {
    margin: auto;
  }

  .background-container {
    position: absolute;
    top: 250px;
    bottom: 0;
    left: 0;
    right: 0;
    background: #F7F9FC;
    overflow: hidden;
  }

  .index-panels {
    min-width: 84%;
    overflow: hidden;
    background: #F7F9FC;
    height: 100%;
    display: flex;
    margin-inline: 8%;
    margin-top: 2%;
    gap: 2%;
  }

  .panel{
    width: 100%;
    background: white;
  }

  .panel::-webkit-scrollbar {
    display: none;
  }

  .events-panels{
    width: 32%;
    display: flex;
    overflow-y: hidden;
    background: white;
    border-radius: 10px;
  }

  .panel-1a{
    overflow-y: hidden
  }

  .news-panels.of4{
    width: 66%;
    display: flex;
    flex-direction: column;
  }

  .news-panels.of3{
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .panel-2{
    border-radius: 10px;
    width: 50%;
  }

  .panel-3{
    border-radius: 10px;
    overflow: hidden;
  }

  .important-news{
    width: 50%;
    display: flex;
    background: white;
    border-radius: 10px;
    padding-bottom: 1.7rem;
  }

  .horizontal-panel{
    position: relative;
    display: flex;
    align-items: center;
    padding: 0;
    width: 100%;
    background-color: #ffffff;
    min-height: 5rem;
    margin-bottom: 2%;
    border-radius: 10px;
    overflow: hidden;
  }

  .news-article{
    flex-grow: 1;
    padding-right: 10px;
  }

  .close-news-article{
    position: absolute;
    top: 0;
    right: 0;
  }

  .panel h1 {
    font-size: 18px;
    margin-left: 1rem;
    margin-top: 0.5rem;
  }

  .old-news {
    margin-top: 2rem;
  }

  .panel div:has(.events, .container.height){
    height: 100%;
  }

  .news-header-title{
    display: flex;
    justify-content: space-between;
  }

  .news-header{
    color: #003b71;
    font-size: 20px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0.25px;
  }

  .news-title{
    position: sticky;
    top: 0;
    padding-top: 18px;
    padding-inline: 18px;
    padding-bottom: 5px;
    background: #FFFFFF;
    z-index: 1;
    border-radius: 10px;
  }

  .news-block{
    display: flex;
    min-height: 0;
    gap: 2%;
  }

  .news-container{
    overflow-y: auto;
    height: 100%;
    padding: 18px;
    padding-bottom: 3.5rem;
    padding-top: 0;
  }

  .news-container-ticker{
    overflow-y: auto;
    height: 100%;
    padding: 18px;
    padding-bottom: 5.5rem;
    padding-top: 0;
  }

  .news-container::-webkit-scrollbar {
    display: none;
  }

  .news-container-ticker::-webkit-scrollbar {
    display: none;
  }

  .favorites-button-text{
    font-size: 16px;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: 0.25px;
    text-align: left;
    text-transform: capitalize;
  }

  .event-header-title{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-left: 5px;
  }

  .event-list {
    padding-bottom: 1.9rem;
  }

  .live-events{
    margin-right: 6%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 0;
  }

  .all-events{
    display: flex;
    align-items: center;
    font-size: 20px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0.25px;
  }

  .event-header{
    position: sticky;
    top: 0;
    background-color: #FFFFFF;
    z-index: 8;
  }

   /deep/ .v-input--selection-controls__input {
    margin-right: 0.1vw;
   }

</style>

<style>
  .index-map-view .buttonGrid .v-btn .v-btn__content {
    padding: 2rem;
  }

  .index-map-view .buttonGrid .v-btn.primary {
    background-color: #1976d2d9 !important;
  }

  .search-bar-img .v-image__image{
    background-position: center 89% !important;
    filter: brightness(1.3);
  }

</style>
