<template>
  <v-list-item
      three-line
      v-if="article"
      class="article-item"
      @click="clicked(article)">
    <div v-if="articleImage" class="px-3">
      <img class="article-image" :src="articleImage" width="100px" height="60px" alt=""/>
    </div>
    <v-list-item-content>
      <v-list-item-title class="wrap-text">
        <slot name="title">
          <span class="article-title">{{article.title}}</span>
        </slot>
      </v-list-item-title>
      <v-list-item-subtitle class="article-body">
        <slot name="text">{{ textClean }}</slot>
      </v-list-item-subtitle>
    </v-list-item-content>
    <NewsDialog :show="showNewsDialog" :news="newsDialogEntry" @close="showNewsDialog=false"/>
  </v-list-item>
</template>

<script>
import moment from "moment/moment";
import NewsDialog from "./NewsDialog.vue";
import { backendMediaUrl } from '@/settings'

export default {
  name: 'NewsLastArticle',
  components: {NewsDialog},
  props: {
    news: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      newsDialogEntry: null,
      showNewsDialog: false,
    }
  },
  computed: {
    articleImage() {
      if (this.article.image) {
        return backendMediaUrl + '/' + this.article.image;
      }

      return null
    },
    article(){
      let sortedNews = [...this.news].sort((a, b) => moment(a.significantUpdateAt).diff(moment(b.significantUpdateAt)));
      return sortedNews.slice(-1)[0];
    },
    textClean() {
      // A version of the text without html, as required for the preview
      var div = document.createElement("div")
      div.innerHTML = this.article.text
      return div.textContent || div.innerText || ""
    },
  },
  methods: {
    clicked(article) {
      this.newsDialogEntry = article;
      this.showNewsDialog = true;
    },
  },
}
</script>

<style scoped>

  .article-title {
    font-size: 15px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.2px;
    text-align: left;
  }

  .wrap-text {
    white-space: normal;
  }

  .article-item {
    cursor: pointer;
    padding-block: 10px;
    padding-right: 36px !important;
    padding-left: 0;
  }

  .article-image{
    display: flex;
  }
</style>